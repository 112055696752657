/* Base Styles */
@media (min-width: 0em) {
  body {
    margin: 0;
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
    padding: 0;
  }

  html, body {
    width: 100%;
    background-color: #0f0f0f;
    overflow-x: hidden;
  }

  .home {
    text-align: center;
    padding: 2rem;
    margin-top: 130px;
  }

  .home .content p {
    color: grey;
    font-size: 20px;
    margin-bottom: 50px;
  }

  .home .content span {
    color: #495BFA !important;
  }

  .home .content p {
    font-size: 1.3em;
    font-weight: lighter;
  }

  .topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0f0f0f;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    left: 0;
  }

  .logo {
    height: 80px;
  }

  .navbar {
    display: flex;
    gap: 1rem;
    margin-right: 15px;
  }

  .navbar a {
    text-decoration: none;
    color: white;
    font-size: .9rem;
    padding: 0.5rem 1rem;
  }

  .login-button {
    background-color: #495BFA;
    color: white !important;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }

  .cta-button {
    position: relative;
    background-color: #495BFA;
    text-decoration: none;
    color: white;
    padding: .8rem 1.8em;
    border: none;
    border-radius: 5px;
    font-size: .8rem;
    cursor: pointer;
  }

  .hero {
    margin-top: 200px;
  }

  .note {
    margin-top: 1rem;
    color: gray;
  }

  .about {
    margin-top: 4rem;
  }

  .about-section {
    display: flex;
    align-items: center;
    margin: 2rem 0;
  }

  .about-section.reverse {
    flex-direction: row-reverse;
  }

  .about-section .text {
    flex: 1;
    padding: 1rem;
  }

  .about-section .image {
    flex: 1;
    padding: 1rem;
  }

  .about-section .image img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }

  #about {
    position: absolute;
    top: 925px;
  }

  .support {
    padding: 2rem;
    margin-top: 4rem;
    text-align: center;
  }

  .support-content {
    display: inline-block;
    text-align: center;
  }

  .support-logo {
    display: block;
    margin: 0 auto 1rem;
  }

  #cs-footer-274 {
    padding: 2rem;
    margin-top: 4rem;
    text-align: center;
  }

  .cs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cs-logo-group {
    margin-top: 50px;
    margin-bottom: .1rem;
  }

  .cs-nav {
    color: white;
    list-style: none;
    padding: 0;
  }

  .cs-nav-li {
    margin-bottom: .5rem;
  }

  .cs-nav-link {
    color: grey;
    text-decoration: none;
    font-size: 1rem;
  }

  .cs-logo-img {
    position: relative;
  }

  .feature-section {
    padding: 6rem 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-top: 325px;
  }

  .feature-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
    gap: 1.5rem;
  }

  .feature-block.tight {
    gap: 1.5rem;
  }

  .feature-images {
    position: relative;
    width: 100%;
    max-width: 800px;
    height: auto;
  }

  .feature-images img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 2px solid #222;
  }

  .feature-content {
    max-width: 700px;
    color: white;
  }

  .feature-content.tight {
    max-width: 700px;
  }

  .feature-title {
    font-size: 2.2rem;
    color: white;
    margin-bottom: 1rem;
  }

  /* NOTES section: stacked layout */
  .stacked-notes-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }

  .stacked-images {
    position: relative;
    width: 100%;
    max-width: 800px;
    height: auto;
  }

  .img-wrapper {
    position: absolute;
    width: 100%;
    max-width: 700px;
    border-radius: 10px;
    overflow: hidden;
  }

  .img-wrapper.top {
    z-index: 2;
    top: 150px;
    left: 100px;
  }

  .img-wrapper.bottom {
    z-index: 1;
    top: 0;
    left: 0;
  }

  .img-wrapper img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 2px solid #222;
  }

  .img-label {
    position: absolute;
    bottom: 10px;
    left: 15px;
    background: #495BFA;
    color: white;
    font-size: 0.85rem;
    padding: 0.25rem 0.6rem;
    border-radius: 4px;
    z-index: 3;
  }
}

/* Responsive Design */
@media (min-width: 0em) {
  .home .content {
    margin-top: -20px;
  }

  .home .content h1 {
    font-size: 2rem;
    color: white;
  }

  .home .content p {
    font-size: 1rem;
  }

  .about-section {
    flex-direction: column;
  }

  .about-section .image, .about-section .text {
    flex: none;
    padding: 0;
  }

  .about-section .image {
    order: -1;
  }

  .feature-block {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .stacked-notes-section {
    flex-direction: row;
    justify-content: space-between;
  }

  .stacked-images {
    flex: 1;
    position: relative;
    min-height: 450px;
  }

  .feature-content.tight {
    flex: 1;
  }
}

@media (min-width: 48em) {
  .content h1 {
    font-size: 3.5rem !important;
  }

  .home .content p {
    font-size: 1.25rem !important;
  }

  .about-section {
    flex-direction: row;
  }

  .about-section .image, .about-section .text {
    padding: 1rem;
  }

  .about-section .image {
    order: 0;
  }

  .topbar {
    font-size: 2rem !important;
  }

  .feature-block {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 64em) {
  .content h1 {
    font-size: 3.5rem !important;
  }

  .home .content p {
    font-size: 1rem !important;
  }

  .about-section {
    flex-direction: row;
  }

  .about-section .image, .about-section .text {
    padding: 1rem;
  }

  .about-section .image {
    order: 0;
  }
}

.grid-view {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  padding: 20px;
  margin-left: 150px;
  margin-right: 150px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.3s, border 0.3s;
}

.grid-item:hover {
  transform: scale(0.95);
  border: 2px solid #555;
}

.grid-item.folder i, .grid-item.folder .file-name {
  color: white;
}

.grid-item.file i, .grid-item.file .file-name {
  color: grey;
}

.file-name {
  margin-top: 5px;
}
