.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    color: black !important;
  }
  
  .login-form {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    box-shadow: none;
  }
  
  .login-form input[type="password"],
  .login-form input[type="email"] {
    background-color: #121111;
    color: white;
    padding: 1rem;
    border: 1px solid #3a3a3a;
    border-radius: 25px;
    outline: none;
    margin-bottom: 1rem;
  }
  
  .login-form button {
    padding: 1rem;
    background-color: #221170;
    color: #fff;
    font-size: 15px !important;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .login-form button:hover {
    background-color: #331c99;
  }

  .signup-text {
    color: grey;
    text-align: center;
    font-size: 15px !important;
  }
  
  .signup-text a {
    text-decoration: none;
    color: #2464b3;;
  }
  
  .signup-text a:hover {
    color: #285388;
  }
  
  .login-logo {
    display: block;
    max-width: 250px;
    margin: 0 auto 1rem;
    margin-bottom: -30px;
  }

  .error {
    background-color: rgba(156, 0, 0, 0.582); /* Transparent red background */
    border: rgb(184, 0, 0) solid 2px;
    color: white;
    padding: 1rem;
    border-radius: 25px;
    text-align: center;
  }
